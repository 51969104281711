<template>
	<div class="container ar">
		<div class="top">
			<div class="title">كل المقالات</div>
			<div class="search">
				<search-box></search-box>
			</div>
		</div>
		<div class="main">
			<loader card v-if="loading || load"></loader>
			<article-card
				v-else
				v-for="article in articles"
				:key="article"
				class="cardo"
				:id="article"
			></article-card>
			<p
				v-if="articles.length === 0 && !loading"
				class="fs-3 text-center fw-light m-5"
			>
				لا يوجد مقالات
			</p>
		</div>
	</div>
</template>

<script>
import ArticleCard from "../../components/ArticleCard.vue";
import SearchBox from "../../components/SearchBox.vue";
import Loader from "../../components/Loader.vue";
export default {
	props: ["load"],
	components: {
		ArticleCard,
		SearchBox,
		Loader,
	},
	data() {
		return {
			articles: [],
			loading: true,
		};
	},
	watch: {
		$route(newRoute) {
			if (newRoute.params.id && newRoute.name === "all-articles") {
				this.fetchService();
			}
		},
	},
	methods: {
		fetchService() {
			this.loading = true;
			this.articles = [];
			this.$store
				.dispatch("tabs/fetchById", { id: this.$route.params.id })
				.then((data) => {
					this.articles = data.articles;
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
	mounted() {
		this.fetchService();
	},
};
</script>

<style scoped>
.container {
	padding: 0 20px !important;
}
.top {
	display: flex;
	justify-content: space-between;
	padding: 50px 20px;
}
.title {
	font-family: changaBoldSemi;
	font-size: 24px;
	color: var(--blue-color);
}

.main {
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 50px;
	row-gap: 20px;
	margin-bottom: 50px;
	margin-bottom: 50px;
}
@media only screen and (max-width: 992px) {
	.main {
		grid-template-columns: 1fr;
	}
}
@media only screen and (max-width: 768px) {
	.top {
		flex-wrap: wrap;
	}
}
@media only screen and (min-width: 1400px) {
	.title {
		font-size: 26px;
	}
}
</style>
