<template>
	<router-link class="out" :to="{ name: 'article', params: { id: item.id } }">
		<div class="title">{{ item.name }}</div>
		<div class="image">
			<img :src="item.primary_image" alt="primary_image" />
		</div>
		<div class="paragraph">
			{{ item.summary.length > 130 ? item.summary.slice(0, 130) + '...' : item.summary }}
		</div>
	</router-link>
</template>

<script>
export default {
	props: ["item"],
};
</script>

<style scoped>
.title {
	color: var(--blue-color);
	font-family: changaBold;
	font-size: 20px;
	margin: 10px 0 20px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}
.image {
	width: 100%;
	height: 250px;
}
.image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 20px;
}
.paragraph {
	font-size: 14px;
	margin: 15px 0 5px;
}
@media only screen and (min-width: 1400px) {
	.title{
		font-size: 22px;
	}
	.paragraph {
		font-size: 18px;
	}
}
</style>