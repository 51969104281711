<template>
	<div class="container ar mt-5" id="top">
		<div class="top"></div>
		<div class="contant">
			<div class="contant-top">
				<div class="tabs">
					<div class="tabs-iner">
						<div
							class="tabs-item"
							v-for="(tab, index) in tabs"
							:key="index"
							@click="tabsId = tab.id"
							:class="tab.id === tabsId ? 'active' : ''"
						>
							{{ tab.name }}
						</div>
					</div>
				</div>
				<div class="search">
					<search-box></search-box>
				</div>
			</div>
			<div class="contant-iner">
				<div class="main">
					<div class="main-top">
						<div
							class="mt-item"
							v-for="(type, index) in articleTypes"
							:key="type"
							@click="changeArticleType(index)"
						>
							{{ type }}
						</div>
					</div>
					<div class="main-contant">
						<loader card v-if="loading || loadLabs"></loader>
						<article-card
							v-else
							v-for="article in articlesToShow"
							:key="article"
							class="mb-3"
							:id="article"
						></article-card>
						<p
							v-if="articlesToShow.length === 0 && !loading"
							class="fs-3 text-center fw-light my-5"
						>
							لا يوجد مقالات
						</p>
					</div>
				</div>
				<div class="side">
					<div class="side-slider">
						<div
							v-show="!loading"
							id="myCarouselHome"
							class="carousel slide"
							data-bs-ride="carousel"
						>
							<div class="carousel-indicators myCarouselButtons">
								<button
									type="button"
									data-bs-target="#myCarouselHome"
									:data-bs-slide-to="index"
									:aria-label="'Slide' + index"
									v-for="(article, index) in sliceFeaturedArticles.slice(0, 3)"
									:key="index"
									id="homeCarouselButton"
								></button>
							</div>
							<div class="carousel-inner">
								<div
									class="carousel-item"
									v-for="article in sliceFeaturedArticles.slice(0, 3)"
									:key="article.id"
									id="homeCarouselItem"
								>
									<slide-item :item="article"></slide-item>
								</div>
							</div>
						</div>
					</div>
					<div
						class="pined"
						v-if="!loading && sliceFeaturedArticles.slice(3, 10).length > 0"
					>
						<div class="sub-title">
							<img src="/Icons/pinned.png" alt="" />
							مقالات مثبتة
						</div>
						<div
							class="pin-item"
							v-for="article in sliceFeaturedArticles.slice(3, 10)"
							:key="article.id"
						>
							<router-link
								:to="{ name: 'article', params: { id: article.id } }"
							>
								<div class="pi-date">
									{{ pinArticleDate(article.updated_at) }}
								</div>
								<div class="pi-title">{{ article.name }}</div>
								<div class="pi-para">{{ article.summary }}</div>
							</router-link>
						</div>
					</div>
					<div class="join" v-if="!loading">
						<div v-if="!sent" class="sub-title">
							<img src="/Icons/bell.png" alt="" />
							اشترك بقناتنا البريدية
						</div>
						<p v-if="!sent" class="sub-title">
							انضم الى قائمة الأشتراك في المحتوى لتصلك تنبيهات عن اخر الاخبار
							فور نزولها
						</p>
						<div class="j-form">
							<form @submit.prevent="submit" ref="form" novalidate>
								<div class="sent-done" v-if="sent">
									شكراً لاشتراكك بقناتنا البريدية
								</div>
								<input
									v-model="firstName"
									type="name"
									placeholder="الاسم الأول"
									class="fname name"
									:class="{ 'input-invalid': notValid }"
									:disabled="loadToSubmit"
									v-if="!sent"
									required
								/>
								<input
									v-model="lastName"
									type="name"
									placeholder="الاسم الاخير"
									class="lname name"
									:class="{ 'input-invalid': notValid }"
									:disabled="loadToSubmit"
									v-if="!sent"
									required
								/>
								<input
									v-model="phone"
									type="tel"
									placeholder="رقم الهاتف"
									class="phone"
									:class="{ 'input-invalid': notValid }"
									:disabled="loadToSubmit"
									v-if="!sent"
									required
								/>
								<input
									v-model="mail"
									type="email"
									placeholder="البريد الالكتروني"
									class="email"
									:class="{ 'input-invalid': notValid }"
									:disabled="loadToSubmit"
									v-if="!sent"
									required
								/>
								<button
									class="btno"
									:disabled="loadToSubmit"
									:class="{ 'bg-secondary': loadToSubmit }"
									type="submit"
									v-if="!sent"
								>
									<div
										v-if="loadToSubmit"
										class="spinner-border spinner-border-sm text-light"
										role="status"
									>
										<span class="visually-hidden">Loading...</span>
									</div>
									<span v-else>اشتراك</span>
								</button>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ArticleCard from "../../components/ArticleCard.vue";
import SearchBox from "../../components/SearchBox.vue";
import slideItem from "./slideItem.vue";
import Loader from "../../components/Loader.vue";
import { mapState } from "vuex";
import moment from "moment";
export default {
	props: ["loadLabs"],
	components: {
		ArticleCard,
		SearchBox,
		slideItem,
		Loader,
	},
	data() {
		return {
			articleTypes: ["الأحدث", "الاكثر قراءة"],
			loading: true,
			tabsId: 0,
			isNotFeatured: true,

			firstName: null,
			lastName: null,
			phone: null,
			mail: null,

			notValid: false,
			loadToSubmit: false,
			sent: false,
		};
	},
	computed: {
		articlesToShow() {
			if (this.isNotFeatured)
				return this.articles.filter((c) => c.Tabs_id.includes(this.tabsId));
			else
				return this.articles
					.filter((c) => c.Tabs_id.includes(this.tabsId))
					.sort((a, b) => {
						return b.view - a.view;
					})
					.slice(0, 5);
		},
		sliceFeaturedArticles() {
			return this.featuredArticles.slice(3, 10);
		},
		...mapState({
			articles: (state) => state.articles.articles,
			featuredArticles: (state) => state.articles.featuredArticles,
			tabs: (state) => state.tabs.tabs,
		}),
	},
	methods: {
		pinArticleDate(x) {
			return moment(x).format("MMM DD YYYY");
		},
		changeArticleType(index) {
			document.querySelectorAll(".mt-item").forEach((e, i) => {
				if (i != index) e.classList.remove("active");
				else e.classList.add("active");
			});

			index === 0 ? (this.isNotFeatured = true) : (this.isNotFeatured = false);
		},
		submit() {
			if (this.$refs.form.checkValidity()) {
				this.notValid = false;
				this.loadToSubmit = true;
				this.sent = false;
				this.$store
					.dispatch("feedback/create", {
						id: 12,
						firstname: this.firstName,
						lastname: this.lastName,
						phone: this.phone,
						mail: this.mail,
					})
					.then(() => {
						this.id = 12;
						this.firstName = null;
						this.lastName = null;
						this.phone = null;
						this.mail = null;
						this.sent = true;
					})
					.finally(() => {
						this.loadToSubmit = false;
					});
			} else {
				this.notValid = true;
			}
		},
		fetchService() {
			Promise.all([this.$store.dispatch("articles/fetchAll")]).finally(() => {
				this.loading = false;
				this.tabsId = this.tabs[0].id;
				document
					.querySelectorAll(".carousel-indicators button")
					.forEach((c) => c.classList.remove("active"));
				document
					.querySelectorAll(".carousel-item")
					.forEach((c) => c.classList.remove("active"));

				document
					.querySelectorAll(".carousel-indicators button:first-child")
					.forEach((c) => c.classList.add("active"));
				document
					.querySelectorAll(".carousel-item:first-child")
					.forEach((c) => c.classList.add("active"));
			});
		},
	},
	mounted() {
		this.changeArticleType(0);
		this.fetchService();
	},
};
</script>

<style scoped>
.contant {
	margin-bottom: 50px;
}
.contant-top {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.tabs {
	flex: 2;
	overflow: auto;
}
::-webkit-scrollbar {
	display: none;
}
.search {
	flex: 1;
}
.tabs-iner {
	display: flex;
	justify-content: flex-start;
	padding-left: 10px;
}
.tabs-item {
	padding: 3px 20px 5px;
	font-size: 14px;
	background: var(--borders-color);
	border: 1px solid #ffff;
	border-radius: 15px;
	position: relative;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
}
.ar .tabs-item:not(:first-child) {
	margin-right: 10px;
}
.en .tabs-item:not(:first-child) {
	margin-left: 10px;
}
.tabs-item.active {
	border: 1px solid var(--blue-color);
	background: white;
}
.contant-iner {
	display: flex;
}
.main {
	flex: 2;
}
.ar .main {
	padding-left: 20px;
}
.en .main {
	padding-right: 20px;
}
.main-top {
	display: flex;
	justify-content: flex-start;
	border-bottom: 2px solid var(--borders-color);
	margin-bottom: 20px;
}
.mt-item {
	padding: 20px 5px;
	margin: 0 20px;
	margin-bottom: -2px;
	font-size: 16px;
	cursor: pointer;
}
.mt-item:first-child {
	margin-right: 0;
}
.mt-item.active {
	border-bottom: 2px solid black;
}
.side {
	flex: 1;
}
.side > div {
	margin-bottom: 20px;
}
.side-slider {
	margin: 30px 0 !important;
	width: 30vw;
}
#homeCarouselItem {
	height: 23rem;
}
div.sub-title {
	color: var(--blue-color);
	font-family: changaBold;
	font-size: 20px;
	margin: 10px 0;
}
div.sub-title img {
	height: 30px;
	padding: 5px;
}
p.sub-title {
	margin-top: -10px;
	font-size: 12px;
}
.pin-item {
	padding: 20px 0;
	border-bottom: 1px solid var(--borders-color);
}
.pin-item:last-child {
	border: none;
}
.pi-date {
	color: #aaa;
	font-size: 10px;
}
.pi-title {
	font-size: 15px;
	font-family: changaBold;
}
.pi-para {
	font-size: 12px;
}
.j-form form {
	display: grid;
	grid-template-columns: 1fr 1fr;
	max-width: 500px;
}
.j-form form > * {
	padding: 7px 15px;
	margin: 5px;
	border-radius: 20px;
	font-size: 13px;
	min-width: 0;
}
.input-invalid:invalid {
	border: 1px solid red;
}
input {
	outline: none;
	border: 1px solid transparent;
	background: var(--grey-color);
}
.phone,
.email {
	grid-column-start: 1;
	grid-column-end: 3;
}
.btno {
	width: 100px;
	height: 34px;
	justify-content: center;
	align-items: center;
	border: none;
	display: inline-flex;
	background: var(--yellow-color);
	color: var(--blue-color);
	font-family: changaBold;
}
form > .sent-done {
	grid-column-start: 1;
	grid-column-end: 3;
	font-size: 22px;
	font-weight: 700;
	text-align: center;
	line-height: 60px;
}
@media only screen and (max-width: 992px) {
	#homeCarouselItem {
		height: 24rem;
	}
	.contant-top {
		display: block;
	}
	.tabs {
		margin-bottom: 20px;
	}
	.contant-iner {
		display: block;
	}
	.ar .main,
	.en .main {
		padding: 0;
	}
	.tabs-iner {
		padding: 0;
	}
	.side-slider {
		width: 100%;
	}
}
@media only screen and (min-width: 1400px) {
	.tabs-item {
		font-size: 18px;
	}
	#homeCarouselItem {
		height: 24rem;
	}
	.pi-date {
		font-size: 12px;
	}
	.pi-title {
		font-size: 18px;
	}
	.pi-para,
	p.sub-title {
		font-size: 16px;
	}
	div.sub-title {
		font-size: 22px;
	}
	.j-form form > * {
		font-size: 16px;
	}
	.btno {
		font-family: 16px;
	}
}
</style>
