<template>
	<loader
		line
		v-if="loading || load"
		style="
			height: 80vh;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 40vw;
			margin: auto;
		"
	></loader>
	<div class="mt-5" v-else>
		<vue-easy-lightbox
			scrollDisabled
			escDisabled
			moveDisabled
			:visible="visible"
			:imgs="article.images"
			:index="index"
			@hide="handleHide"
		></vue-easy-lightbox>
		<div class="container ar article" id="top">
			<div class="top" ref="top">
				<div class="info">
					<div class="date">
						{{ date(article.updated_at) }}
					</div>
					<div class="title">
						{{ article.name }}
					</div>
					<div class="top-bottom">
						<div class="type">{{ findTheTab().name }}</div>
						<div class="time">{{ article.estimated_time }}</div>
					</div>
				</div>
				<div class="search"><search-box></search-box></div>
			</div>

			<div class="contant" v-if="!loading">
				<div
					class="main"
					v-html="article.description + `<div class='background'></div>`"
				></div>
				<div class="sub">
					<div v-if="!loading" class="article-images">
						<div class="ai-title">صور المقالة</div>
						<div class="ai-container" :class="overflowImages ? 'overflow' : ''">
							<div
								class="ai-image"
								v-for="(image, index) in relatedImagesNew()"
								:key="image"
								@click="showMultiple(index)"
							>
								<img
									@load="calcOverflowImage"
									:src="image"
									alt="relatad images"
									ref="image"
								/>
							</div>
						</div>
					</div>
					<div class="reletad-articles">
						<div class="ra-title">مقالات متعلقة</div>
						<div
							v-for="article in relatedArticles().slice(0, 4)"
							:key="article.id"
							class="ra-item"
						>
							<router-link
								:to="{ name: 'article', params: { id: article.id } }"
							>
								<div class="ra-item-date">{{ date(article.updated_at) }}</div>
								<div class="ra-item-title">
									{{ article.name }}
								</div>
								<div class="ra-item-para">{{ article.summary }}</div>
							</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import SearchBox from "../../components/SearchBox.vue";
import Loader from "../../components/Loader.vue";
import { mapState } from "vuex";
import VueEasyLightbox from "vue-easy-lightbox";
import moment from "moment";

export default {
	props: ["load"],
	components: {
		VueEasyLightbox,
		SearchBox,
		Loader,
	},
	data() {
		return {
			article: {},
			overflowImages: 0,
			loading: true,

			/*light box*/
			imgs: [],
			visible: false,
			index: 0,
		};
	},
	watch: {
		$route(newRoute) {
			if (newRoute.params.id && newRoute.name === "article") {
				this.fetchService();
			}
		},
	},
	computed: {
		...mapState({
			tabs: (state) => state.tabs.tabs,
			articles: (state) => state.articles.articles,
		}),
	},
	methods: {
		relatedArticles() {
			const ra = [];
			this.articles.forEach((e) => {
				if (e.id != this.article.id) {
					e.Tabs_id.forEach((tab) => {
						this.article.Tabs_id.forEach((x) => {
							if (x === tab) {
								if (!ra.includes(e)) ra.push(e);
							}
						});
					});
				}
			});
			return ra;
		},
		relatedImagesNew() {
			if (this.article.images.length > 3) {
				this.overflowImages = this.article.images.length - 3;
				return this.article.images.slice(0, 3);
			}
			return this.article.images;
		},
		date(x) {
			return moment(x).format("MMM DD YYYY");
		},
		findTheTab() {
			return this.tabs.find((c) => c.id == this.article.Tabs_id[0]);
		},
		calcOverflowImage() {
			if (this.overflowImages) {
				document
					.querySelector(".ai-image:last-child")
					.setAttribute("data-after", `+ ${this.overflowImages}`);
			}
		},
		fetchService() {
			this.loading = true;
			this.$store
				.dispatch("articles/fetchById", { id: this.$route.params.id })
				.then((data) => {
					this.article = data;
					if (this.articles.length === 0) {
						this.$store.dispatch("articles/fetchAll");
					}
					this.loading = false;
				})
				.finally(() => {
					this.calcOverflowImage();
					document.querySelector(".background").style.top = `-${
						this.$refs.top.clientHeight + 20
					}px`;
					window.addEventListener("resize", () => {
						if (document.querySelector(".background") && this.$refs.top) {
							document.querySelector(".background").style.top = `-${
								this.$refs.top.clientHeight + 20
							}px`;
						}
					});
				});
		},

		/*light box*/
		showMultiple(index) {
			this.imgs = this.article.images;
			this.index = index;
			this.show();
		},
		show() {
			this.visible = true;
		},
		handleHide() {
			this.visible = false;
		},
	},
	mounted() {
		this.fetchService();
	},
};
</script>
<style>
.article .main * {
	font-size: 18px !important;
	font-family: changa !important;
	color: var(--yellow-color);
	list-style-position: inside;
}
.article .main img {
	max-width: 100%;
	height: auto;
}
@media only screen and (min-width: 1400px) {
	.article .main * {
		font-size: 20px !important;
	}
}
.main {
	position: relative;
}
.background {
	background: url("../../../public/Images/background.png");
	position: absolute;
	/* top: -110px; */
	right: -10px;
	left: 30px;
	bottom: 0;
	z-index: -1;
}
@media (max-width: 768px) {
	.background {
		top: -190px;
		left: -10px;
	}
	.search * {
		color: white;
	}
}
</style>
<style scoped>
.top {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 30px 0 20px;
}
.info {
	flex: 2;
}
.date {
	font-size: 14px;
	color: #aaa;
}
.title {
	font-size: 22px;
	font-family: changaBold;
	color: var(--yellow-color);
}
.top-bottom {
	display: flex;

	padding-top: 5px;
}
.type {
	background: var(--borders-color);
	border-radius: 20px;
	padding: 5px 20px;
	line-height: 10px;
	font-size: 10px;
}
.ar .type {
	margin-left: 10px;
}
.en .type {
	margin-right: 10px;
}
.time {
	padding: 5px;
	font-size: 10px;
	line-height: 10px;
	color: #aaa;
}
.search {
	flex: 1;
}
.contant {
	display: flex;
	margin-bottom: 50px;
}
.main {
	flex: 2;
	font-size: 16px;
	margin-bottom: 1.2rem;
}
.ar .main {
	padding-left: 50px;
}
.en .main {
	padding-right: 50px;
}
.article-title {
	font-weight: 600;
	margin: 0;
}
.article-image {
	width: 100%;
	margin: 1rem 0;
}
.article-image img {
	width: 100%;
}
.sub {
	flex: 1;
}
.article-images {
	margin-bottom: 30px;
}
.ai-title {
	margin-bottom: 10px;
	font-size: 14px;
	color: var(--blue-color);
	font-family: changaBold;
}
.ai-container {
	display: flex;
	flex-wrap: wrap;
}
.ai-image {
	height: 100px;
	width: 100px;
	margin-bottom: 10px;
	position: relative;
}
.ai-image img {
	width: 100%;
	height: 100%;
	border-radius: 10px;
	object-fit: cover;
}
.ar .ai-image {
	margin-left: 10px;
}
.en .ai-image {
	margin-right: 10px;
}
.ai-image:last-child {
	margin: 0;
}
.ai-image img {
	width: 100%;
	height: 100%;
}
.overflow .ai-image:last-child::after {
	content: attr(data-after);
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	background: #1118;
	border-radius: 10px;
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 26px;
	font-weight: bold;
}
.ra-title {
	color: var(--blue-color);
	font-family: changaBold;
}
.ra-item {
	padding: 20px 0;
	border-bottom: 1px solid var(--borders-color);
}
.ra-item:last-child {
	border-bottom: none;
}
.ra-item-date {
	color: #aaa;
	font-size: 10px;
	padding: 0 0 2px;
}
.ra-item-title {
	font-family: changaBold;
	font-size: 14px;
}
.ra-item-para {
	font-size: 12px;
	line-height: 18px;
	max-height: 54px;
	overflow: hidden;
}

@media only screen and (max-width: 768px) {
	.top {
		flex-wrap: wrap;
	}
	.top > div {
		flex: unset;
	}
	.info {
		margin-bottom: 20px;
	}
	.contant {
		flex-wrap: wrap;
	}
	.contant > div {
		flex: unset;
	}
	.ar .main {
		padding-left: 0;
	}
	.en .main {
		padding-right: 0;
	}
}
@media only screen and (min-width: 1400px) {
	.title {
		font-size: 26px;
	}
	.ra-item-para {
		font-size: 14px;
	}
}
</style>
