import * as types from '../mutations-types';

export default {
	namespaced: true,

	state: {
		articles: [],
		featuredArticles: [],
	},

	actions: {
		fetchAll({ commit }) {
			return axios.get('/articles').then((response) => {
				commit(types.STORE_ARTICLES, response.data);
				commit('findFeaturedArticles', response.data);
				return response.data;
			});
		},

		fetchById({ commit }, { id }) {
			return axios.get(`/articles/${id}`).then((response) => {
				return response.data;
			});
		},
	},

	mutations: {
		[types.STORE_ARTICLES](state, articles) {
			state.articles = articles;
		},
		findFeaturedArticles(state, articles) {
			var arr = articles.filter(c => c.is_featured)
			state.featuredArticles = arr;
		},
	},
};
