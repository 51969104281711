import ContactUs from '../views/ContactUs/ContactUs.vue';
import Article from '../views/Article/Article.vue';
import Home from '../views/Home/Home.vue';
import AllArticles from '../views/Article/AllArticles.vue';

export default [
	{
		path: '/',
		name: 'home',
		component: Home,
	},
	{
		path: '/contact-us',
		name: 'contact-us',
		component: ContactUs,
	},
	{
		path: '/article/:id',
		name: 'article',
		component: Article,
	},
	{
		path: '/all-articles/:id',
		name: 'all-articles',
		component: AllArticles,
	},
];
