<template>
	<div class="container ar position-relative" :class="{ next: $route.name !== 'home' }">
		<div class="navo" :class="{ 'navo-inner': $route.name !== 'home' }">
			<div class="drawer" @click="openDrawer = !openDrawer">
				<i class="fa fa-bars"></i>
			</div>
			<div class="brand">
				<div class="shape">
					<div class="image">
						<div class="name">
							<router-link :to="{ name: 'home' }">
								<span> غيث </span>
								أبو هلال
							</router-link>
							<div class="info">محلل اقتصادي ومستشار مالي</div>
						</div>
						<img src="/Shapes/shape.png" alt="" class="bimg" />
						<img src="/Shapes/shape_y.png" alt="" class="yimg" />
					</div>
					<img src="/Images/Gaith-abo-helal.png" alt="" class="selfimg" />
					<div class="slider">
						<div id="myCarousel" class="carousel slide" data-bs-ride="carousel">
							<div class="carousel-indicators myCarouselButtons">
								<button
									type="button"
									v-for="(article, index) in featuredArticles.slice(0, 3)"
									:key="index"
									data-bs-target="#myCarousel"
									:data-bs-slide-to="index"
									:aria-label="'Slide' + index"
								></button>
							</div>
							<div class="carousel-inner">
								<div
									class="carousel-item"
									v-for="(article, index) in featuredArticles.slice(0, 3)"
									:key="index"
								>
									<router-link
										:to="{ name: 'article', params: { id: article.id } }"
									>
										{{
											article.name.length > sentenceLength()
												? article.name.slice(0, sentenceLength()) + "..."
												: article.name
										}}
									</router-link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="list" :class="{ open: openDrawer && !loading }">
				<loader
					line
					style="width: 50vw; margin-top: 5px"
					v-if="loading"
				></loader>
				<ul
					:class="{
						'border-start': $route.name === 'home' && this.onLargeAndDown,
					}"
					v-else
				>
					<li v-for="tab in tabs" :key="tab.id" @click="openDrawer = false">
						<router-link :to="{ name: 'all-articles', params: { id: tab.id } }">
							{{ tab.name }}
						</router-link>
					</li>
					<li v-if="false">
						<a class="text-secondary" @click="openDrawer = false"
							>عن غيث ابو هلال</a
						>
					</li>
					<li>
						<router-link
							:to="{ name: 'contact-us' }"
							@click="openDrawer = false"
						>
							تواصل معنا
						</router-link>
					</li>
				</ul>
			</div>
		</div>
		<div class="bgimg">
			<img src="/Images/background.jpg" alt="" />
		</div>
	</div>
</template>

<script>
import Loader from "../components/Loader.vue";
import { windowWidthMixin } from "../mixins";
import { mapState } from "vuex";

export default {
	components: {
		Loader,
	},
	data() {
		return {
			loading: true,
			featuredArticlesToshow: [],
			openDrawer: false,
		};
	},
	mixins: [windowWidthMixin],
	computed: {
		...mapState({
			tabs: (state) => state.tabs.tabs,
			featuredArticles: (state) => state.articles.featuredArticles,
		}),
	},
	methods: {
		fetchService() {
			this.$store.dispatch("tabs/fetchAll").finally(() => {
				this.loading = false;
				this.$emit("labFethched");
			});
		},
		sentenceLength() {
			if (this.onLargeAndUp) {
				return 50;
			} else if (this.currentBreakpoint === "md") {
				return 80;
			} else {
				return 50;
			}
		},
	},
	mounted() {
		this.fetchService();
	},
};
</script>

<style scoped>
.disp {
	display: none;
}
.container {
	padding: 0;
	overflow: hidden;
}
.navo {
	display: flex;
	width: 100%;
	height: 60px;
	transition: all 300ms;
	background: white;
	color: var(--font-color);
}
.navo .list a,
.navo .list a:link,
.navo .list a:visited,
.navo .list a:hover {
	transition: all 300ms;
	color: var(--font-color);
}
.navo-inner {
	transition-delay: 300ms;
	background: var(--blue-color);
	color: white;
}
.navo-inner .list a,
.navo-inner .list a:link,
.navo-inner .list a:visited,
.navo-inner .list a:hover {
	transition-delay: 300ms;
	color: white;
}
.brand {
	width: 21vw;
	position: relative;
}
.shape {
	position: absolute;
	top: 0;
	right: -1px;
	height: 25vw;
	width: 35vw;
	transition: 500ms;
	margin-right: -1px;
}
.image {
	position: relative;
	top: 0;
	height: 100%;
	width: 100%;
}
.name {
	position: absolute;
	z-index: 3;
	top: 50%;
	right: 70px;
	transform: translateY(-50%);
	color: var(--yellow-color);
	font-size: 4vw;
	line-height: 4vw;
	font-family: changaBold;
	transition: 300ms ease-out;
}
.next .name {
	transition-delay: 10ms, 400ms;
	-moz-transition-property: all, color;
	top: 30px;
	right: 30px;
	font-size: 30px;
	color: var(--blue-color);
}
.name span {
	display: block;
	color: white;
	font-family: inherit;
	transition: 300ms;
}
.next .name span {
	transition-delay: 400ms;
	transition-property: color;
	color: var(--blue-color);
	display: inline-block;
}
.info {
	color: white;
	font-size: 1.3vw;
	line-height: 50px;
	transition-delay: 200ms;
}
.next .info {
	display: none;
}
.selfimg {
	position: absolute;
	left: -10%;
	bottom: 0;
	z-index: 4;
	width: 45%;
	transition: 300ms;
}
.next .selfimg {
	opacity: 0;
}
.image img {
	position: absolute;
	height: 100%;
	width: 100%;
}
.bimg {
	z-index: 1;
}
.yimg {
	z-index: 2;
	width: 101%;
	transform: translateX(100%);
	transition: 200ms linear;
}
.next .yimg {
	transition-delay: 500ms;
	transform: translateX(0);
}
.slider {
	position: absolute;
	top: 10vw;
	right: 40vw;
	z-index: 4;
	font-size: 32px;
	color: var(--blue-color);
	font-family: changaBold;
	width: 40vw;
}
.slider-item {
	height: 130px;
	background: red;
	direction: rtl;
}
.my__pagination {
	position: absolute;
	bottom: -20px;
	background: blue;
}
.list {
	color: inherit;
	padding: 20px;
	height: 100%;
	z-index: 4;
}
.list ul {
	list-style: none;
	display: flex;
	color: inherit;
	margin: 0;
	padding: 0;
}
.list ul li {
	width: max-content;
	padding: 0 12px;
}
.list ul li a {
	font-size: 14px;
}
.list ul li:first-child {
	padding-right: 0;
}
.bgimg {
	width: 100%;
	height: calc(25vw - 60px);
	transition: 200ms linear;
	position: relative;
}
.bgimg::before {
	content: " ";
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	background: #f7c13d99;
}
.next .bgimg {
	transition-delay: 200ms;
	height: 0px;
}
.bgimg img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
#myCarousel {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 9vw;
}
.drawer {
	cursor: pointer;
	display: none;
}
@media only screen and (max-width: 992px) {
	.drawer {
		display: block;
		margin-right: auto;
		padding: 20px;
		color: black;
		font-size: 20px;
		z-index: 5;
	}
	.next .drawer {
		filter: brightness(100);
	}
	#myCarousel {
		height: 16vw;
	}
	.navo {
		direction: ltr;
		justify-content: end;
	}
	.shape {
		height: 50vw;
		width: 70vw;
	}
	.list {
		overflow-x: clip;
		padding: 0;
		width: 0;
		height: 100%;
		transition: all 200ms;
		visibility: hidden;
	}
	.list.open {
		width: 145px;
		visibility: visible;
	}
	.list ul {
		flex-direction: column;
		height: 50vw;
		justify-content: space-between;
		padding: 10px;
		align-items: center;
		background: var(--blue-color);
		color: white;
	}
	.navo .list a,
	.navo .list a:link,
	.navo .list a:visited,
	.navo .list a:hover {
		color: white;
	}

	.list ul li {
		font-size: 10px;
		padding: 1vw 0;
	}
	.bgimg {
		height: calc(50vw - 60px);
	}
	.slider {
		right: 4vw;
		top: 20vw;
		direction: rtl;
	}
	.name {
		right: 10px;
		top: 7vw;
		line-height: 20px;
		font-size: 5vw;
		text-align: right;
		direction: rtl;
	}
	.name span {
		display: inline-block;
	}
	.info {
		font-size: 2vw;
		line-height: 5vw;
	}
	.next .list {
		position: absolute;
		overflow: clip;
		top: 60px;
		width: 100%;
		height: 0;
	}
	.next .list.open {
		height: 280px;
	}
	.next .list ul {
		height: 280px;
	}
}
@media only screen and (max-width: 576px) {
	.next .shape {
		width: 100vw;
	}
}

/** carousole */
.carousel-item > a {
	font-family: changaBold;
	font-size: 34px;
	color: var(--blue-color);
	padding-bottom: 20px;
}

.carousel-indicators [data-bs-target],
.carousel-indicators [data-bs-target].active {
	background: white;
	opacity: 1;
}
@media only screen and (max-width: 992px) {
	.carousel-item {
		margin-bottom: 10px;
		line-height: 4vw;
	}
	.carousel-item > a {
		font-family: changa;
		font-size: 3.3vw;
		color: white;
	}
	.slide > .myCarouselButtons {
		bottom: -10px !important;
	}
}

@media only screen and (max-width: 576px) {
	.carousel-indicators [data-bs-target],
	.carousel-indicators [data-bs-target].active {
		display: none;
	}
	.slider {
		width: 55%;
	}
	.info {
		font-size: 0.6rem;
	}
	.carousel-item > a {
		font-size: 1.1rem;
	}
}
@media only screen and (max-width: 400px) {
	.carousel-item > a {
		font-size: 0.9rem;
	}
	.list {
		position: absolute;
		right: 0;
		top: 0;
		height: 50vw;
	}
	.list li {
		opacity: 0;
		transition: opacity .15s;
	}
	.list.open {
		width: 80%;
	}
	.list.open li {
		opacity: 1;	
	}
	.next .list.open {
		width: 100%;
	}
	.list ul li {
		padding: 0;
	}
}
@media only screen and (min-width: 1400px) {
	.list ul li a {
		font-size: 18px;
	}
}
</style>
