<template>
	<div class="container mt-5">
		<div>
			<div class="main-image center">
				<div class="back-image">
					<div class="image" id="image">
						<img src="/Images/Gaith-abo-helal.png" alt="" />
					</div>
				</div>
				<div class="name center">غيث أبو هلال</div>
				<div class="descrp center">محلل اقتصادي ومستشار مالي</div>
			</div>
			<loader line v-if="loadContacts" style="width: 30vw; margin: 100px auto"></loader>
			<template v-if="!loadContacts">
				<div class="title center">تابعوني على</div>
				<div class="social">
					<social-card
						v-if="getContact('youtube')"
						:name="'Youtube'"
						:to="getContact('youtube').Value"
						:image="`Icons/youtube.png`"></social-card>
					<social-card
						v-if="getContact('tiktok')"
						:name="'Tik Tok'"
						:to="getContact('tiktok').Value"
						:image="`Icons/tiktok.png`"></social-card>
					<social-card
						v-if="getContact('twitter')"
						:name="'X (Twitter)'"
						:to="getContact('twitter').Value"
						:image="`Icons/twitter.png`"></social-card>
					<social-card
						v-if="getContact('whatsapp')"
						:name="'Whatsapp channel'"
						to="https://whatsapp.com/channel/0029Va6HHgd0QeamXiXBSh00"
						:image="`Icons/whatsapp-channels.png`"></social-card>
				</div>

				<div class="title center" id="contact">تواصل معي على</div>
				<div class="social mb-5">
					<social-card
						v-if="getContact('instagram')"
						:name="'Instagram'"
						:to="getContact('instagram').Value"
						:image="`Icons/instagram.png`"
						class="small"></social-card>
					<social-card
						v-if="getContact('linkedin')"
						:name="'Linked in'"
						:to="getContact('linkedin').Value"
						:image="`Icons/linkedin.png`"
						class="small"></social-card>
					<social-card
						v-if="getContact('outlook')"
						:name="'Outlook'"
						:to="getContact('outlook').Value"
						:image="`Icons/outlook.png`"
						class="small"></social-card>
					<social-card
						v-if="getContact('whatsapp')"
						:name="'Whatsapp'"
						:to="getContact('whatsapp').Value"
						:image="`Icons/whatsapp.png`"
						class="small"></social-card>
					<social-card
						v-if="getContact('telegram')"
						:name="'Telegram'"
						:to="getContact('telegram').Value"
						:image="`Icons/telegram.png`"
						class="small"></social-card>
					<social-card
						v-if="getContact('telegram_channel')"
						:name="'Telegram channel'"
						:to="getContact('telegram_channel').Value"
						:image="`Icons/telegram_channel.png`"
						class="small"></social-card>
					<social-card
						v-if="getContact('phone')"
						:name="'Phone'"
						:to="getContact('phone').Value"
						:image="`Icons/phone.png`"
						class="small"></social-card>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
import SocialCard from '../../components/SocialCard.vue';
import Loader from '../../components/Loader.vue';
import { mapGetters } from 'vuex';
export default {
	props: ['loadContacts'],
	components: {
		SocialCard,
		Loader,
	},
	data() {
		return {
			loading: true,
			contactUs: [],
			follow: [],
		};
	},
	computed: {
		...mapGetters({
			getContact: 'contacts/getContact',
		}),
	},
};
</script>
<style scoped>
.center {
	width: max-content;
	margin: auto;
}
.main-image {
	margin-top: 30px;
	margin-bottom: 30px;
}
.back-image {
	margin: auto;
	width: 250px;
	height: 250px;
	border-radius: 50%;
	background: var(--yellow-color);
	overflow: hidden;
	padding-top: 30px;
	box-shadow: 0 3px 7px #333a;
}
.image {
	height: 100%;
	width: 100%;
	animation: entering 1s cubic-bezier(0.08, 0.82, 0.17, 1) 0.2s both;
}
@keyframes entering {
	from {
		transform: translateY(1000px);
	}
	top {
		transform: translateY(0);
	}
}
.image img {
	height: 100%;
	width: 100%;
	object-fit: contain;
}
.name {
	font-size: 32px;
	text-shadow: 0px 3px 5px #333a;
	font-family: changaBoldSemi;
}
.descrp {
	font-size: 20px;
	text-shadow: 0px 3px 5px #333a;
	line-height: 18px;
}
.title {
	font-size: 22px;
	margin-top: 50px;
	margin-bottom: 10px;
	position: relative;
}
.title-arrow {
	height: 22px;
	position: absolute;
	left: -30px;
	top: 10px;
}
.title:hover .title-arrow {
	transform: translateY(4px) scale(1.2);
}
.social,
.follow {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
}
.social > div,
.follow > div {
	margin: 15px;
}
.follow {
	margin-bottom: 50px;
}
@media only screen and (max-width: 992px) {
	.social > div,
	.follow > div {
		margin: 10px;
	}
}
@media only screen and (max-width: 576px) {
	.social,
	.follow {
		flex-wrap: wrap;
	}
	.social > div,
	.follow > div {
		margin: 4px;
	}
}
</style>
