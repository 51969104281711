<template>
	<div class="up">
		<div class="search-image">
			<img src="/Icons/Search.png" alt="" />
		</div>
		<input type="text" placeholder="البحث" class="search-inp" />
	</div>
</template>

<script>
export default {};
</script>

<style scoped>
.up {
	border: 1px solid var(--borders-color);
	border-radius: 20px;
	height: 35px;
	width: 100%;
	padding: 5px 10px;
	display: flex;
	max-width: 400px;
}
.search input {
	border: none;
	outline: none;
	font-size: 14px;
	height: 100%;
	padding: 0 10px;
	width: 100%;
	background: transparent;
}
.search-image {
	width: 24px;
	padding: 3px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.search-image img {
	width: 100%;
	filter: brightness(3);
}

@media only screen and (min-width: 1400px) {
	.up {
		height: 40px;
	}
	.search input {
		font-size: 18px;
	}
}
</style>
