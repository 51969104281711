import { createStore } from 'vuex';
import sliderImages from './modules/slider-images';
import tabs from './modules/tabs';
import articles from './modules/articles';
import contacts from './modules/contacts';
import feedback from './modules/feedback';

export default createStore({
	modules: {
		sliderImages,
		tabs,
		articles,
		contacts,
		feedback
	},
});
