<template>
	<div>
		<Header @labFethched="labs = false" />
		<router-view :loadLabs="labs" :loadContacts="contacts"></router-view>
		<Footer @contactsFethched="contacts = false" />
	</div>
</template>

<script>
import Header from "./views/Header.vue";
import Footer from "./views/Footer.vue";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "../src/assets/font-awsome-all";

export default {
	data() {
		return {
			labs: true,
			contacts: true,
		};
	},
	components: {
		Header,
		Footer,
	},
};
</script>
<style>
@import "~bootstrap/dist/css/bootstrap.min.css";
@font-face {
	font-family: changa;
	src: url("./assets/Fonts/Changa/static/Changa-Regular.ttf");
}
@font-face {
	font-family: changaBold;
	src: url("./assets/Fonts/Changa/static/Changa-Bold.ttf");
}
@font-face {
	font-family: changaBoldSemi;
	src: url("./assets/Fonts/Changa/static/Changa-SemiBold.ttf");
}
* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
	color: var(--font-color);
	font-family: changa;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
}
.container {
	margin: 0;
	max-width: unset;
	padding: 0 70px;
}
.ar {
	direction: rtl;
}
.en {
	direction: ltr;
}
:root {
	--font-color: #333;
	--yellow-color: #f7c13d;
	--blue-color: #203344;
	--borders-color: #e5e5e4;
	--grey-color: #ececec;
}
li {
	color: inherit;
}
a {
	text-decoration: none;
	color: inherit;
}
a:hover {
	color: inherit;
}
@media only screen and (max-width: 992px) {
	.container {
		padding: 0 20px;
	}
}
svg.vel-icon {
	fill: white;
}
/*    carousle      */
.carousel > button > span {
	visibility: hidden;
}
.slide {
	margin-bottom: 50px;
}
.slide > .myCarouselButtons {
	margin: 0;
	bottom: -30px;
	justify-content: flex-start;
}
.slide > .myCarouselButtons > button {
	width: 8px;
	height: 8px;
	border: 1px solid transparent;
	border-radius: 10px;
	background: #a6a6a6;
}
.slide > .myCarouselButtons > button.active {
	width: 20px;
	background: var(--yellow-color);
}
</style>
