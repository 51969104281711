<template>
	<div style="overflow: hidden">
		<div class="card-loader" v-if="isValid('card')">
			<div class="small top load"></div>
			<div class="big load"></div>
			<div class="small bottom load"></div>
		</div>
		<div class="line" v-if="isValid('line')"></div>
	</div>
</template>

<script>
export default {
	props: ["line", "card", "image"],

	methods: {
		isValid(prop) {
			return this[prop] || typeof this[prop] === "string" ? true : false;
		},
	},
};
</script>

<style scoped>
.card-loader {
	border: 1px solid #eee;
	border-radius: 20px;
	height: 190px;
	padding: 30px;
}
.big {
	height: 20px;
	width: 60%;
	border-radius: 20px;
	margin: 10px 0;
}
.small {
	height: 15px;
	width: 40%;
	border-radius: 20px;
}
.bottom {
	margin-top: 70px;
}
.load {
	background: #eee;
	overflow: hidden;
	position: relative;
}
.load::after {
	content: "";
	position: absolute;
	top: 0;
	bottom: 0%;
	width: 150px;
	background: linear-gradient(
		270deg,
		#eee 5%,
		var(--yellow-color) 20%,
		#f7c23dc7 70%,
		#eee
	);
	animation: 1.5s linear 0s infinite normal none running loading;
}
.line {
	width: 100%;
	height: 10px;
	margin: 0;
	padding: 0 !important;
	position: relative;
}
.line::after {
	content: "";
	position: absolute;
	bottom: 0;
	top: 0;
	transform: skew(-30deg);
	width: 20%;
	background-color: var(--yellow-color);
	animation: 1.5s linear 0s infinite normal none running loading;
}
@keyframes loading {
	0% {
		width: 0%;
		right: -10%;
	}
	20% {
		width: 20%;
	}
	60% {
		width: 40%;
		right: 50%;
	}
	100% {
		width: 50%;
		right: 100%;
	}
}
</style>
