<template>
	<div class="main1">
		<a :href="findLink(to)" target="_blank">
			<div class="main2">
				<div class="image">
					<img :src="image" alt="" />
				</div>
				<div class="name">{{ name }}</div>
			</div>
		</a>
	</div>
</template>

<script>
export default {
	props: {
		image: { default: 'phone' },
		name: { default: null },
		to: { default: {} },
	},
	methods: {
		findLink(link) {
			if (this.name === 'Whatsapp') {
				return 'https://wa.me/' + link;
			} else if (this.name === 'Phone') {
				return 'tel://' + link;
			} else {
				return link;
			}
		},
	},
};
</script>

<style scoped>
.main1 {
	background: linear-gradient(to right bottom, var(--yellow-color), var(--blue-color));
	min-width: 200px;
	max-width: 200px;
	height: 200px;
	padding: 3px;
	border-radius: 15px;
}
.main2 {
	border-radius: 12px;
	background: white;
	width: 100%;
	height: 100%;
	padding: 20px 10px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
}
.image {
	width: 60%;
	transition: 500ms;
}
.image img {
	height: 100%;
	width: 100%;
}
.name {
	font-size: 26px;
	line-height: 1.1em;
	text-align: center;
}
.main1:hover .image {
	transform: scale(1.1);
}
.small.main1 {
	min-width: 150px;
	max-width: 150px;
	height: 150px;
}
.small.main2 {
	padding: 10% 10%;
}
.small .name {
	font-size: 20px;
}
@media only screen and (max-width: 768px) {
}

@media only screen and (max-width: 576px) {
	.main1 {
		min-width: 35vw;
		max-width: 35vw;
		height: 34vw;
	}
	.small.main1 {
		min-width: 26vw;
		max-width: 26vw;
		height: 26vw;
	}
	.name {
		font-size: 20px;
	}
	.small .name {
		font-size: 14px;
	}
}
</style>
