<template>
	<router-link
		class="d-inline-block"
		:to="{ name: 'article', params: { id: id.id } }"
	>
		<div class="out">
			<div class="contant">
				<div class="main">
					<div class="date">{{ date }}</div>
					<div class="txt">
						<div class="title">
							{{ id.name }}
						</div>
						<div class="para">{{ id.summary }}</div>
					</div>
					<div class="bottom">
						<div class="type" v-for="tab in id.Tabs_id" :key="tab">
							{{ findTheTab(tab) }}
						</div>
						<div class="time">{{ id.estimated_time }}</div>
					</div>
				</div>
				<div class="image">
					<img :src="id.primary_image" alt="article.primary_image" />
				</div>
			</div>
		</div>
	</router-link>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
export default {
	props: ["id"],
	data() {
		return {};
	},
	computed: {
		date() {
			return moment(this.id.updated_at).format("MMM DD YYYY");
		},
		...mapState({
			tabs: (state) => state.tabs.tabs,
		}),
	},
	methods: {
		findTheTab(x) {
			return this.tabs.find((c) => c.id === x).name;
		},
	},
};
</script>

<style scoped>
.out {
	padding: 0 30px;
}
.contant {
	padding: 30px 0;
	height: 100%;
	display: flex;
	align-items: center;
	border-bottom: 1px solid var(--borders-color);
}
.out:last-child .contant,
.out:nth-last-child(2) .contant {
	border-bottom: 1px solid #ffff;
}
.out:hover {
	border-radius: 10px;
	box-shadow: 0 0 15px #aaa3;
}
.out:hover .contant {
	border-bottom: 1px solid #ffff;
}
.main {
	flex: 3;
	display: flex;
	flex-direction: column;
}
.date {
	color: #aaa;
	font-size: 14px;
}
.txt {
	padding-left: 10px;
}
.title {
	font-family: changaBold;
	font-size: 18px;
	margin-bottom: 1rem;
}
.para {
	font-size: 14px;
	margin-bottom: 1rem;
}
.bottom {
	display: flex;
	flex-wrap: wrap;
	margin-top: auto;
	padding-top: 5px;
}
.type {
	background: var(--borders-color);
	border-radius: 20px;
	padding: 5px 20px;
	line-height: 10px;
	font-size: 10px;
	margin-bottom: 5px;
}
.ar .type {
	margin-left: 10px;
}
.en .type {
	margin-right: 10px;
}
.time {
	padding: 5px;
	font-size: 10px;
	line-height: 10px;
	color: #aaa;
}
.image {
	width: 200px;
	height: 160px;
	margin: 0 10px;
}
.image img {
	height: 100%;
	width: 100%;
	border-radius: 5px;
	object-fit: cover;
}
@media only screen and (max-width: 768px) {
	.contant {
		padding: 1rem 0;
	}
	.out {
		padding: 0 1rem;
	}
	.image {
		margin: 0;
		width: 160px;
	}
	.type,
	.time {
		font-size: 0.7rem;
	}
}
@media only screen and (max-width: 500px) {
	.contant {
		flex-direction: column;
	}
	.txt {
		padding: 0;
	}
	.image {
		order: -1;
		width: 100%;
		margin: 0 auto;
		margin-bottom: 1rem;
	}
}
@media only screen and (min-width: 1400px) {
	.title {
		font-size: 22px;
	}
	.type,
	.time {
		font-size: 1rem;
		padding-top: 8px;
		padding-bottom: 8px;
	}
	.para {
		font-size: 18px;
	}
}
</style>
