export const windowWidthMixin = {
    data(){
        return {
            windowWidth: window.innerWidth
        }
    },

    computed:{
        onXXLargeAndUp(){
            return this.windowWidth > 1400;
        },
        onXLargAndUp(){
            return this.windowWidth > 1200;
        },
        onLargeAndUp(){
            return this.windowWidth > 992;
        },
        onMediumAndUp(){
            return this.windowWidth > 768;
        },
        onSamllAndUp(){
            return this.windowWidth > 576;
        },
        
        onXXLargeAndDown(){
            return this.windowWidth < 1400;
        },
        onXLargAndDown(){
            return this.windowWidth < 1200;
        },
        onLargeAndDown(){
            return this.windowWidth < 992;
        },
        onMediumAndDown(){
            return this.windowWidth < 768;
        },
        onSamllAndDown(){
            return this.windowWidth < 576;
        },

        currentBreakpoint(){
            return this.findCurrentBreakpoint();
        }
    },

    methods:{
        findCurrentBreakpoint(){
			if (this.windowWidth > 1400){
				return 'xxl'
			} 
			else if (this.windowWidth > 1200){
				return 'xl'
			} 
			else if (this.windowWidth > 992){
				return 'l'
			} 
			else if (this.windowWidth > 768){
				return 'md'
			} 
			else if (this.windowWidth > 576){
				return 'sm'
			} 
			else {
				return 'xs'
			} 
		},
        onResize(){
			this.windowWidth = window.innerWidth;
		}
    },

	mounted() {
        window.addEventListener('resize', this.onResize);
    },

    beforeUnmount(){
		window.removeEventListener('resize', this.onResize);
	},
}