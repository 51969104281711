import * as types from "../mutations-types";

export default {
	namespaced: true,

	state: {
		tabs: [],
	},

	actions: {
		fetchAll({ commit }) {
			return axios.get("/tabs").then((response) => {
				commit(types.STORE_TABS, response.data);
				return response.data;
			});
		},
		fetchById({ commit }, { id }) {
			return axios.get(`/tabs/${id}`).then((response) => {
				return response.data;
			});
		},
	},

	mutations: {
		[types.STORE_TABS](state, tabs) {
			state.tabs = tabs;
		},
	},
};
