<template>
	<div class="container ar">
		<div class="top">
			<div class="top-contant">
				<div class="info">
					<router-link :to="{name: 'home'}" class="info-title">
						<span>غيث</span>
						أبو هلال
					</router-link>
					<div class="info-sub-title">محلل اقتصادي ومستشار مالي</div>
					<div class="info-para">
						<p>
							تخرجت من جامعة حلب - سوريا, تخصص محاسبة, خبرة في المحاسبة وإدارى
							شركات الذهب لاكثر من 15 عام, خبرة في عالم التجارة منذ عام 2006,
							اكتب واشارك مقالات على العديد من المواقع الدولية على سبيل المثال
							Investing.com
						</p>
					</div>
				</div>
				<div class="subjects">
					<div class="footer-title">المواضيع</div>
					<div class="subjects-main">
						<ul>
							<li v-for="(tab, index) in tabs" :key="index">
								<router-link
									:to="{ name: 'all-articles', params: { id: tab.id } }"
								>
									{{ tab.name }}
								</router-link>
							</li>
						</ul>
					</div>
				</div>
				<div class="contact">
					<div class="social">
						<div class="footer-title">التواصل</div>
						<div class="social-main">
							<p>
								ارحب دائما باسئلتكم واستفساراتكم عبر اي من وسائل التواصل التي
								تفضلونها
							</p>
							<div class="social-logos" v-if="!loading">
								<div class="social-logo-link">
									<a :href="link('facebook')" target="_blank">
										<i class="fab fa-facebook-f" style="color: #fff"></i>
									</a>
								</div>
								<div class="social-logo-link">
									<a :href="link('twitter')" target="_blank">
										<i class="fab fa-twitter" style="color: #fff"></i>
									</a>
								</div>
								<div class="social-logo-link">
									<a :href="link('telegram')" target="_blank">
										<i class="fab fa-telegram-plane" style="color: #fff"></i>
									</a>
								</div>
								<div class="social-logo-link">
									<a :href="link('outlook')" target="_blank">
										<img src="/Icons/outlook-footer.png" alt="" />
									</a>
								</div>
								<div class="social-logo-link">
									<a :href="'https://api.whatsapp.com/send?phone=' + link('whatsapp')" target="_blank">
										<i class="fab fa-whatsapp" style="color: #fff"></i>
									</a>
								</div>
							</div>
						</div>
					</div>
					<div class="telegram">
						<div class="footer-title">انضم على قناتي التلغرام</div>
						<a
							:href="link('telegram_channel')"
							target="_blank"
							class="telegram-logo en"
						>
							<i class="fab fa-telegram-plane"></i>
							GaithChannel
						</a>
					</div>
				</div>
			</div>
		</div>
		<div class="bottom">
			<div class="bottom-contant">
				<div class="copy">copyright@{{ new Date().getFullYear() }}</div>
				<a href="https://www.beetronix.com" target="_blank" class="beet">BEETRONIX</a>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex";
export default {
	data() {
		return {
			loading: true,
		};
	},
	computed: {
		...mapState({
			tabs: (state) => state.tabs.tabs,
			contacts: (state) => state.contacts.contacts,
		}),
	},
	methods: {
		fetchService() {
			this.$store.dispatch("contacts/fetchAll").finally(() => {
				this.$emit("contactsFethched");
				this.loading = false;
			});
		},
		link(x) {
			var to;
			this.contacts.forEach((e) => {
				if (e.Key === x) {
					to = e.Value;
				}
			});
			return to;
		},
	},
	mounted() {
		this.fetchService();
	},
};
</script>

<style scoped>
.container {
	background: var(--blue-color);
}
* {
	color: white;
	font-size: 15px;
}
.top {
	padding: 40px 0 10px;
}
.top-contant {
	display: flex;
	justify-content: space-between;
}
.info {
	padding-left: 20px;
	max-width: 400px;
}
.info-title {
	color: var(--yellow-color);
	font-size: 30px;
	font-family: changaBoldSemi;
}
.info-title span {
	font-size: inherit;
	font-family: inherit;
}
.info-sub-title {
	font-size: 14px;
	margin-bottom: 12px;
}
p {
	font-size: 14px;
}
.footer-title {
	color: var(--yellow-color);
	font-family: changaBoldSemi;
	margin-bottom: 10px;
}
.subjects {
}
.subjects-main {
	display: flex;
	padding-top: 10px;
}
.subjects-main ul {
	list-style: none;
	display: grid;
	grid-template-columns: auto auto;
	column-gap: 30px;
}
.subjects-main ul li {
	padding: 5px 0;
	font-size: 14px;
	line-height: 24px;
	white-space: nowrap;
}
.contact {
	max-width: 300px;
}
.social {
	margin-bottom: 10px;
}
.social-logos {
	display: flex;
}
.social-logo-link {
	height: 30px;
	width: 30px;
	border: 1px solid white;
	border-radius: 50%;
	margin: 0 5px;
}
.social-logo-link a {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
}
.social-logo-link svg,
.social-logo-link img {
	width: 60%;
	height: 60%;
	object-fit: cover;
	filter: brightness(100);
}
.telegram-logo {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 30px;
	width: 120px;
	border: 1px solid white;
	border-radius: 15px;
	margin: 10px 0;
	font-size: 13px;
	text-align: center;
}
.telegram-logo svg {
	filter: brightness(100);
	margin-right: 6px;
}
.bottom-contant {
	padding: 5px 0 10px;
	border-top: 1px solid white;
	display: flex;
	justify-content: space-between;
}
.bottom-contant * {
	font-size: 12px;
}
.beet {
	font-weight: 900;
}
@media only screen and (max-width: 768px) {
	.top-contant {
		flex-direction: column;
		align-items: center;
	}
	.top-contant > div {
		margin-bottom: 20px;
	}
	.top-contant * {
		text-align: center;
	}
	.subjects-main {
		justify-content: space-between;
	}
	.subjects-main ul {
		padding: 0;
		column-gap: 10px;
	}
	.social-logos {
		justify-content: center;
	}
	.telegram-logo {
		margin: auto;
	}
}
@media only screen and (min-width: 1400px) {
	p, a {
		font-size: 16px;
	}
	.subjects-main ul li {
		font-size: 16px;
	}
	.footer-title {
		font-size: 18px;
	}
	.info-title {
		font-size: 34px;
	}
	.info-sub-title {
		font-size: 16px;
	}
	.info {
		max-width: 500px;
	}
	.social-logo-link{
		width: 36px;
		height: 36px;
	}
	.telegram-logo{
		width: 140px;
		height: 34px;
		font-size: 16px;
	}
	.bottom-contant *{
		font-size: 14px;
	}
}
</style>
